@font-face {
  font-family: vodafoneLtFont;
  src: url("../public/assets/fonts/fontsvf/Arabic/GESSTwoLight-Light.otf");
}
@font-face {
  font-family: vodafoneArBoldFont;
  src: url("../public/assets/fonts/fontsvf/Arabic//GESSTwoBold-Bold.otf");
}
@font-face {
  font-family: vodafoneArMediumFont;
  src: url("../public/assets/fonts/fontsvf/Arabic/GESSTwoMedium-Medium.otf");
}

@font-face {
  font-family: vodafoneFont;
  src: url("../public/assets/fonts/fontsvf/English/VodafoneLt-Regular.ttf");
  unicode-range: U+0020-024F;
}
@font-face {
  font-family: vodafoneLtFont;
  src: url("../public/assets/fonts/fontsvf/English/VodafoneLt.ttf");
  unicode-range: U+0020-024F;
}

.App {
  /*text-align: center;*/
}

body.rtl,
.rtl > * {
  font-family: "vodafoneLtFont" !important;
}

body {
  font-family: "vodafoneLtFont" !important;
  font-size: 1rem !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
div {
  font-family: "vodafoneLtFont" !important;
}
.single-page .wrapper label,
.horizontalMenu,
.item1-links a,
div,
p {
  font-size: 1.1rem !important;
}
.h4,
h4 {
  font-size: 1.3rem !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footer-main {
  text-align: left;
}

.text-danger {
  text-align: left;
  font-size: 12px;
  font-weight: bold;
}
.media-body {
  text-align: left;
}
.marquee {
  border-bottom: 1px solid #f5f5f5;
}
.marquee-item {
  color: #ffffff;
  background: #e60000;
  font-weight: bold;
}
.marquee-item::after {
  content: "|";
  color: #ffffff;
  padding: 1rem;
}
@media only screen and (max-width: 991px) {
  .horizontal-header .smllogo {
    margin-top: 5px;
  }
}

.desktoplogo img {
  width: 40%;
}
.bg-dark-transparent {
  background: #94080b !important;
}
.bg-background2:before {
  background: linear-gradient(to right, #94080b, #ff140091);
}
.sticky-wrapper.is-sticky .horizontal-main {
  background: #000000b5 !important;
}
.sticky-wrapper.is-sticky .horizontalMenu > .horizontalMenu-list > li > a,
.sticky-wrapper.is-sticky
  .horizontalMenu
  > .horizontalMenu-list
  > li
  > a
  > .fa {
  color: #ffffff;
}

.badge-primary {
  background-color: #e60000;
}
.btn-primary {
  background-color: #e60000 !important;
  border-color: #e60000 !important;
}

.table-striped tbody tr:nth-of-type(even),
.table > :not(caption) > * > * {
  background-color: #ebebeb;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: #ffffff;
}
.table thead th {
  background-color: #e60000;
  color: #fff;
  font-weight: bolder !important;
}
.table-primary {
  font-size: 14px;
}
.badge-info {
  background-color: #006a82;
}

.number {
  font-weight: 600;
}

.bg-background:before,
.bg-background-color:before,
.bg-background3:before,
.bg-background-5:before,
.bg-background-6:before,
.slider-header .item:before,
.bg-background2:before {
  background: linear-gradient(to right, rgb(185, 0, 1), rgb(157, 0, 1));
}

.font-1vw {
  font-size: 1vw;
}

.logo-responsive {
  width: 70px;
}
@media (max-width: 767px) {
  .font-1vw {
    font-size: 2vw !important;
  }
  .logo-responsive {
    width: 50px !important;
  }
  .table-top-text {
    font-size: 12px !important;
  }
}

.banner-2.sptb-2 {
  padding-top: 0rem !important; /*padding-top: 4rem !important;*/
  padding-bottom: 0rem !important; /*padding-bottom: 7rem !important;*/
}
.bannerimg {
  padding: 7rem 0 2rem 0;
}

.auc-num {
  font-size: 1.3rem;
}

@media (max-width: 991px) {
  .safari .header-main .sticky-wrapper-custom {
    z-index: 0 !important;
  }
  body.safari.active .horizontalMenucontainer {
    position: relative !important;
  }
  .banner-2.sptb-2 {
    /* padding-bottom: 1.5rem !important; */
    padding-top: 3rem !important;
  }
  .bannerimg {
    padding: 4rem 0 1rem 0 !important;
  }
  .auc-num {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .banner-2 img {
    height: 8rem;
  }
}

.bid-amounts .o-hidden {
  overflow: hidden;
}

.bid-amounts .nav {
  transition: transform 0.4s;
  transform: translateX(50%);
  left: -50%;
}

.bid-amounts .nav.justify-content-end {
  transform: translateX(0);
  left: 0;
}

.bid-amounts .nav-item,
.bid-amounts .nav-link {
  min-width: auto;
  margin-right: 2px;
}

.blink-bg {
  color: #fff;
  padding: 2px;
  display: inline-block;
  border-radius: 5px;
  animation: blinkingBackground 2s infinite;
}
@keyframes blinkingBackground {
  0% {
    background-color: #c01010;
  }
  25% {
    background-color: #fc7e68;
  }
  50% {
    background-color: #ef0a1a;
  }
  75% {
    background-color: #b3521a;
  }
  100% {
    background-color: #d50b04;
  }
}

.breadcrumb-item::before {
  display: none !important;
}
.breadcrumb-item::after {
  display: inline-block !important;
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
  color: rgba(255, 255, 255, 0.3) !important;
  content: "/" !important;
}
.table td {
  vertical-align: middle !important;
}

.terms-list {
  list-style: disc;
  margin: 2rem;
}

.terms-list li {
  padding: 0.5rem;
}

.form-check-input {
  position: static;
  margin-right: 0.3rem;
  margin-left: 0.5rem;
}

.bg-gradient-1 {
  background: #7f7fd5;
  background: -webkit-linear-gradient(to right, #7f7fd5, #86a8e7, #91eae4);
  background: linear-gradient(to right, #7f7fd5, #86a8e7, #91eae4);
}

.bg-gradient-2 {
  background: #654ea3;
  background: -webkit-linear-gradient(to right, #654ea3, #eaafc8);
  background: linear-gradient(to right, #654ea3, #eaafc8);
}

.bg-gradient-3 {
  background: #ff416c;
  background: -webkit-linear-gradient(to right, #ff416c, #ff4b2b);
  background: linear-gradient(to right, #ff416c, #ff4b2b);
}

.bg-gradient-4 {
  background: #007991;
  background: -webkit-linear-gradient(to right, #007991, #78ffd6);
  background: linear-gradient(to right, #007991, #78ffd6);
}

.rounded {
  border-radius: 1rem !important;
}

.btn-demo {
  padding: 0.5rem 2rem !important;
  border-radius: 30rem !important;
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
  text-transform: uppercase;
  font-weight: bold !important;
}

.btn-demo:hover,
.btn-demo:focus {
  color: #fff;
  background: rgba(255, 255, 255, 0.5);
}

.countdown {
  text-transform: uppercase;
  font-weight: bold;
}

.countdown span {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 3rem;
  margin-left: 0.8rem;
}

.countdown span:first-of-type {
  margin-left: 0;
}

.countdown-circles {
  text-transform: uppercase;
  font-weight: bold;
}

.countdown-circles span {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.countdown-circles span:first-of-type {
  margin-left: 0;
}

.row-divider {
  box-sizing: content-box;
  height: 0 !important;
  overflow: visible;
  margin-top: 0.6rem;
  margin-bottom: 0.7rem;
  border: 0;
  border-top: 1px solid #ffffff;
}

.desktoplogo {
  float: right;
}
.horizontalMenu {
  float: left;
}
.rtl .desktoplogo {
  float: left;
}

.rtl .horizontalMenu {
  float: right;
}
.active-auc-title {
  margin-top: 5rem !important;
}
@media (min-width: 992px) {
  .sticky-wrapper.is-sticky .desktoplogo-1 {
    float: right;
  }
  .rtl .sticky-wrapper.is-sticky .desktoplogo-1 {
    float: left;
  }
}

@media (max-width: 780px) {
  .active-auc-title {
    margin-top: 0rem !important;
  }
}

.card-icon-custom {
  width: 33% !important;
}
.card-icon-custom img {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  height: auto;
}

.floatingMenu {
  position: absolute;
  left: 0;
  top: 15rem;
}

.rtl .floatingMenu {
  right: 0 !important;
}

.floatingMenuBtn {
  border-radius: 50px;
}

.activity-bg {
  height: 51vw;
  background-image: url("../public/assets/dashboard/bg.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
}
.activity-clock {
  color: #fff !important;
}

.live-footer .foot-copyright {
  font-size: 1.3rem !important;
}
.live-footer .foot-comp-name {
  font-size: 1.3rem !important;
}
